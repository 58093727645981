import React from "react";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

import './LoadingMeasurementTable.scss';

import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {ToggleButton} from "primereact/togglebutton";
import {Measurement} from "../../../serviceapi";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import Papa from "papaparse"
import {apisStore} from "../../../context/GlobalStates";
import {apisType} from "../../../serviceapi/ApiImports";
import NotificationPopUp from "util/NotificationPopUp";

type LoadingMeasurementTableProps = {
    readerId?: string,

    showProgressIndicator(): void,

    hideProgressIndicator(): void,
    setVisible(visible: boolean): void
}

type ExportObject = {
    Name: string,
    Date: string,
    Value: string
}

class LoadingMeasurementTableState {
    measurements: Array<Measurement> = [];
    loading: boolean = true;
    frequency: number = 5;
    refreshToggle: boolean = true;
    search: string = "";
    searching: boolean = false;
    showReader: boolean = false;
    showMeeter: boolean = true;
}

class LoadingMeasurementTable extends React.Component<LoadingMeasurementTableProps, LoadingMeasurementTableState> {
    private measurements: Array<Measurement> = [];
    private interval?: NodeJS.Timer;
    private debounce?: NodeJS.Timer;
    private updatePossible: boolean = true;
    private componentMounted = false;
    private tags: Array<string> = [];
    private dataCollectorApi:apisType;

    constructor(props: Readonly<LoadingMeasurementTableProps>) {
        super(props);
        this.state = new LoadingMeasurementTableState();
        this.dataCollectorApi = apisStore.getState().dataCollector
    }

    componentDidMount() {
        if (!this.componentMounted) {
            this.firstLoad();
            this.componentMounted = true;
        }
    }

    componentDidUpdate(prevProps: Readonly<LoadingMeasurementTableProps>, prevState: Readonly<LoadingMeasurementTableState>, snapshot?: any) {
        if (this.state.refreshToggle) {
            if (this.state.frequency !== prevState.frequency || this.state.refreshToggle !== prevState.refreshToggle) {
                this.stopInterval();
                this.startInterval(this.state.frequency);
            }
        } else {
            if (this.state.refreshToggle !== prevState.refreshToggle) {
                this.stopInterval();
            }
        }

        if (this.state.search !== prevState.search) {
            this.setState({searching: true})
            this.stopDebounce();
            this.startDebounce();
        }

        if (this.state.showReader !== prevState.showReader || this.state.showMeeter !== prevState.showMeeter) {
            if (!this.state.searching && this.componentMounted && this.updatePossible) {

                this.filterDataNames()
                    .then(() => {
                    })
            }
        }


    }

    componentWillUnmount() {
        this.stopInterval();
    }

    private startInterval = (time?: number) => {
        let timeMs = this.state.frequency * 1000;

        if (this.interval) {
            this.stopInterval();
        }

        if (time) {
            timeMs = time * 1000;
        }

        this.updateData();
        this.interval = setInterval(() => {
            if (!this.state.searching && this.componentMounted) {
                this.updateData();
            }
        }, timeMs);
    }

    private stopInterval = () => {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    private startDebounce = () => {
        this.debounce = setTimeout(() => {
            this.filterDataNames()
                .then(() => this.setState({searching: false}))
        }, 750)
    }

    private stopDebounce = () => {
        if (this.debounce) {
            clearTimeout(this.debounce);
        }
    }

    private firstLoad = () => {
        this.getReaderTags()
            .then((result: any) => {
                if (result && this.props.readerId) {
                    if (result.data.length > 0) {

                        let tempSet: Array<string> = [];
                        result.data.forEach((ob: any) => {
                            tempSet.push(ob);
                        })
                        this.tags = tempSet;
                        this.startInterval(this.state.frequency);
                    } else {
                        this.setState({loading: false})
                        this.props.hideProgressIndicator()
                        this.setState({refreshToggle: false});
                    }
                }
            })
    }

    private getReaderTags = () => {
        return this.dataCollectorApi.objectsApi?.getTags_1(this.props.readerId ? this.props.readerId : "")
            .catch((e: any) => {
                this.props.setVisible(false)
                NotificationPopUp.show("Error displaying data", 'error')
            });
    }

    private getData = async (tags?: Array<string>) => {
        return this.dataCollectorApi.measurementApi?.getLatestMeasurements(tags)
            .then((r: any) => r.data)
            .catch((e: any) => {
                console.error(e)
            })
    }

    private loadChunk = async () => {
        let chunkSize = 500;
        let tempData: Array<any> = [];
        let count = 0;

        while (count < this.tags.length) {
            let chunk = this.tags.slice(count, count += chunkSize);
            await this.getData(chunk)
                .then(res => {
                    if (this.componentMounted) {
                        tempData = [...tempData, ...res];
                        if (this.tags.length === tempData.length) {
                            this.measurements = [...tempData];
                        }
                    }
                })
                .catch(er => console.error(er))
        }
    }

    updateData = () => {
        if (this.updatePossible) {
            this.updatePossible = false;
            this.props.showProgressIndicator();

            this.loadChunk()
                .then(() => {
                    if (this.state.loading) {
                        this.setState({loading: false})
                    }
                    this.props.hideProgressIndicator()
                    this.updatePossible = true;
                    if (this.state.search.length > 0) {
                        this.filterDataNames()
                            .then(() => this.setState({searching: false}))
                    } else {
                        this.setState({measurements: this.removeTitle([...this.measurements])});
                    }
                })
                .catch(er => console.error(er))
        }
    }

    private removeTitle = (data: Array<Measurement>) => {
        let tempData: Array<Measurement> = [];
        if (!this.state.showReader || !this.state.showMeeter) {
            data.forEach(obj => {
                let tempOb: Measurement = {...obj};
                let tempSplit = tempOb.meterId?.split(":");
                if (tempSplit && tempSplit.length > 2) {
                    let newMeeterId = "";

                    if (!this.state.showReader && this.state.showMeeter) {
                        newMeeterId += tempSplit[1] + ":";
                    } else if (this.state.showReader && !this.state.showMeeter) {
                        newMeeterId += tempSplit[0] + ":";
                    }

                    for (let i = 2; i < tempSplit.length; i++) {
                        newMeeterId += tempSplit[i];
                    }

                    tempOb.meterId = newMeeterId
                }

                tempData.push(tempOb);
            })
        } else {
            return data
        }
        return tempData;
    }

    private dataTableDateConverter = (rowData: Measurement) => {
        if (rowData.timeStart) {
            return this.convertNumberToDate(rowData.timeStart);
        } else {
            return "-";
        }
    }

    private convertNumberToDate = (dateInNumber: number): string => {
        let dateFromString = new Date(dateInNumber);
        let returnDate = new Date(dateInNumber);

        returnDate.setMinutes(dateFromString.getMinutes() - dateFromString.getTimezoneOffset());

        let returnISO = returnDate.toISOString();

        returnISO = returnISO.replace("T", " ");
        return returnISO.split('.')[0];
    }

    private dataTableValueAndUnit = (rowData: Measurement) => {
        if (typeof rowData.value === 'number' && rowData.unit) {
            return <>{this.formatDataValue(rowData.value)} {rowData.unit}</>;
        }
        if (typeof rowData.value === 'number' && !rowData.unit) {
            return <>{this.formatDataValue(rowData.value)}</>;
        }
        if (typeof rowData.value !== 'number') {
            return <>-</>;
        }
    }

    private formatDataValue = (value: number) => {
        if (Math.abs(value) >= 0.001) {
            return value.toFixed(3);
        } else if (value === 0) {
            return 0;
        } else {
            return this.expo(value)
        }
    }

    private filterDataNames = async () => {
        if (this.state.search.length === 0) {
            await this.setState({measurements: this.removeTitle([...this.measurements])});
        } else {
            await this.setState({
                measurements: this.removeTitle([...this.measurements]
                    .filter((e => e.meterId?.toLocaleLowerCase()
                        .includes(this.state.search.toLowerCase()))))
            });
        }
    }

    private exportNewCSV() {
        let tempDate: Array<ExportObject> = [...this.state.measurements.map(ob => {
            let tempOb: ExportObject = {Name: "-", Date: "-", Value: "-"}

            if (ob.meterId) {
                tempOb.Name = ob.meterId;
            }

            if (ob.timeStart) {
                tempOb.Date = this.convertNumberToDate(ob.timeStart)
            }

            if (ob.value != null && ob.unit != null) {
                tempOb.Value = `${this.formatDataValue(ob.value)} ${ob.unit}`;
            } else if (ob.value != null && !ob.unit) {

                tempOb.Value = `${this.formatDataValue(ob.value)}`;
            }
            return tempOb
        })]

        const csv = Papa.unparse(tempDate);
        const blob = new Blob([csv], {type: 'text/csv'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.props.readerId}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    expo(x
             :
             number, f ?: number
    ) {
        if (!f) {
            f = 2;
        }
        return x.toExponential(f);
    }

    render()
        :
        React.ReactNode {
        if (this.state.loading) {
            return (<></>);
        } else {
            return (
                <div className={"full-window-loading-measurement-table"}>
                    <div className="header-side">
                        <div className={"choices-div-flex"}>
                            <span className="p-input-icon-left">
                                <i className={this.state.searching ? "pi pi-spin pi-spinner" : "pi pi-search"}/>
                                <InputText placeholder="Search"
                                           value={this.state.search}
                                           onChange={event => this.setState({search: event.target.value})}
                                />
                            </span>
                            <div className={"flex-with-gap"}>
                                <span>Show reader id</span>
                                <Checkbox checked={this.state.showReader}
                                          onChange={e => this.setState({showReader: e.checked})}
                                />
                            </div>
                            <div className={"flex-with-gap"}>
                                <span>Show meter id</span>
                                <Checkbox checked={this.state.showMeeter}
                                          onChange={e => this.setState({showMeeter: e.checked})}
                                />
                            </div>
                        </div>

                        <div className={"right-side-functions"}>
                            <Button className={"p-button-sm even-smaller-button"}
                                    icon={"pi pi-download"}
                                    onClick={() => this.exportNewCSV()}
                            />
                            <div>
                                <InputNumber
                                    className={"input-number"}
                                    value={this.state.frequency}
                                    onValueChange={e => this.setState({frequency: e.value ? e.value : 1})}
                                    allowEmpty={false}
                                    min={1}
                                    max={999}
                                    placeholder={"OFF"}
                                    suffix={" sec"}
                                    maxFractionDigits={0}
                                    size={4}
                                    tooltip={"The refresh frequency in seconds"}
                                    tooltipOptions={{position: "bottom"}}
                                />
                                <ToggleButton
                                    onLabel="On" offLabel="Off"
                                    tooltip={"Auto refresh state"}
                                    checked={this.state.refreshToggle}
                                    onChange={(e) => this.setState({refreshToggle: e.value})}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"data-table-window"}>
                        <DataTable value={this.state.measurements}
                                   loading={this.state.loading}
                                   scrollable
                                   scrollHeight='flex'
                                   size={"small"}
                        >
                            <Column field={"meterId"} header="Id" style={{flex: '1 0 20rem'}}/>
                            <Column field={"date"} style={{whiteSpace: "nowrap", flex: '0 0 10.3rem'}} header="Date"
                                    body={this.dataTableDateConverter}/>
                            <Column field={"value"} style={{whiteSpace: "nowrap", flex: '0 0 10rem'}} header="Value"
                                    body={this.dataTableValueAndUnit}/>
                        </DataTable>
                    </div>
                </div>
            )
        }


    }
}

export {
    LoadingMeasurementTable
}